<template>
	<div>
		
		<TableList :form_data_seo.sync="form_data_seo" :scroll="{x: true}" :columns="columns_temp"
			:get_table_list="this.get_table_list" :slot_table_list="slot_table_list" :is_page="false" ref="list"
			@changeStatus='changeStatus' rowKey="id" :where="where" :submit_preprocessing="submit_preprocessing">
			<template slot="form_after">
				<div class='page_content_top page_content'>
					<div v-for='item in tracing_count'>
						<a-statistic :title="item.label" :value="item.value" />
					</div>
				</div>
			</template>
			<template slot="line_num" slot-scope="data">
				<a @click='go_line(data.record)' target="_blank">
					{{data.record.line_num}}
				</a>
			</template>
			<template slot="op" slot-scope="data">
				<a v-if='where.type==1' @click='go_code(data.record)' target="_blank">
					查看溯源码
				</a>
				<a v-if='where.type==2' @click='go_goods_code(data.record)' target="_blank">
					查看溯源码
				</a>
			</template>
			<template slot="before">
				<div class="table_before">
					<div class="left">
						<a-tabs type="card" @change="tab_change" class='tab_info'>
							<a-tab-pane :key="1" tab="基地统计报表"></a-tab-pane>
							<a-tab-pane :key="2" tab="品项统计报表"></a-tab-pane>
						</a-tabs>
					</div>
					
					<div class="right_btn" style='text-align: right;'>
						<a :href="'/manage/tracing.statistics/stasiticsExport','type='+where.type | export_form_seo"
							target="_blank">
							<a-button class="a_btn">导出</a-button>
						</a>
					</div>
				</div>
			</template>

		</TableList>
	</div>
</template>
<script>
	import TableList from "@/components/TableList";
	import {
		orderReport,
		orderReportSub
	} from "@/api/order";
	import {
		getStatisticsTop,
		getFactorySelect,
		getStatisticsList,
		getWarehouseSelect,
		getLinseSelect
	} from "@/api/traceability";
	import {
		getGoods
	} from '@/api/cost'
	let columns = [

		{
			title: "基地",
			dataIndex: "warehouse_name",
		},

		{
			title: '基地地址',
			dataIndex: "address",

		},
		{
			title: '产线总数',
			dataIndex: "line_num",
			scopedSlots: {
				customRender: "line_num"
			}
		},

		{
			title: '出货总量',
			dataIndex: "total_num",

		},
		{
			title: '操作',
			dataIndex: "op",
			scopedSlots: {
				customRender: "op"
			}
		},
	];

	let columns2 = [{
			title: "品项名称",
			dataIndex: "goods_name",
		},
		{
			title: "基地",
			dataIndex: "warehouse_name",
		},
		{
			title: "产线",
			dataIndex: "prd_line",

		},

		{
			title: '出货总量',
			dataIndex: "total_num",

		},
		{
			title: '操作',
			dataIndex: "op",
			scopedSlots: {
				customRender: "op"
			}
		},
	];




	export default {
		name: "Index",
		components: {
			TableList,
		},
		data() {
			return {
				where: {
					type: 1
				},
				tracing_count: [],
				get_table_list: getStatisticsList,
				submit_preprocessing: {
					array_to_string: ['department', 'goods_ids', 'customer_type', 'role']
				},
				details: {
					visit_data: {

					},
				},
				slot_table_list: ['total', 'line_num', 'op'],
				columns,
				columns2,
				columns_temp: columns,
				goods_list: [],
				drawer_visible: false,
				form_data_seo: {
					...this.$config.form_data_seo,
					list: [

						{
							type: "select",
							name: "factory_sap_id",
							title: "出货基地",
							list: [],
						},
						{
							type: "select",
							name: "prd_line",
							title: "产线",
							list: [],
						},
						{
							type: "text",
							name: "goods_name",
							title: "品项名称",
							mode: "default",
							options: {},
							list: [],
						},
						{
							type: "date",
							name: "start_time",
							title: "开始日期",
							options: {},
						},
						{
							type: "date",
							name: "end_time",
							title: "结束日期",
							options: {},
						},



					],

				},
				form_data: {}
			};
		},
		async created() {
			this.get_Statistics_Top()
			this.get_option()
		},
		mounted() {},
		methods: {
			go_line(info) {
				// this.$keep_route.add(this.$options.name);
				this.$router.push({
					path: "/traceability/reportLine",
					query: {
						factory_sap_id: info.factory_sap_id
					}
				});
			},
			go_code(info) {
				this.$keep_route.add(this.$options.name);
				this.$router.push({
					path: "/traceability/code",
					query: {
						factory_sap_id: info.factory_sap_id,
						status:2
					}
				});
			},
			go_goods_code(info) {
				this.$keep_route.add(this.$options.name);
				this.$router.push({
					path: "/traceability/code",
					query: {
						factory_sap_id: info.factory_sap_id,
						goods_id:info.goods_id,
						line_id:info.line_id
					}
				});
			},
			get_option() {
				// 仓库数据
				getFactorySelect().then(res => {
					this.$method.set_form_list(this.form_data_seo.list, 'factory_sap_id', 'list', res.data.list);
				})
				// 产线数据
				getLinseSelect().then(res => {
					this.$method.set_form_list(this.form_data_seo.list, 'prd_line', 'list', res.data.list);
				})

				// 品相
				getGoods().then(res => {
					res.data.list.forEach(it => {
						it.key = it.sap_id
						it.value = it.goods_title + '(' + it.sap_id + ')'
					})
					this.form_data_seo.list.forEach(item => {
						if (item.name == 'goods_name') {
							item.list = res.data.list
						}

					})

				})
			},
			tab_change(key) {
				this.where.type = key;
				this.$nextTick(() => {
					if (key == 1) {
						this.columns_temp = this.columns
					} else {
						this.columns_temp = this.columns2
					}
					this.$refs.list.pagination.page=1
					this.$refs.list.get_list();
					this.get_Statistics_Top()
				})

			},

			changeStatus(data) {
				this.get_Statistics_Top(data)
			},
			get_Statistics_Top(data) {
				getStatisticsTop({
					data: {
						type: this.where.type,
						...data
					}
				}).then(res => {
					this.tracing_count = res.data.list
				})
			},
		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.visit_img {
		width: 60px;
		height: 60px;
	}

	.page_content_top {
		display: flex;

		div {
			flex: 1;
			text-align: center;
		}
	}
</style>